<template>

  <b-card>
    <b-card-text>

      <b-table
        responsive="sm"
        :items="orders"
        :fields="fields"
      >

        <template #cell(total)="data">
          {{ data.value.toFixed(2) }}zł
        </template>

        <template #cell(actions)="row">
          <b-link
            class="primary"
            :to="`/zamowienia/${row.item.id}`"
          >
            zobacz
          </b-link>
        </template>
      </b-table>

    </b-card-text>
  </b-card>
</template>

<script>

import {
  BCard, BCardText, BTable, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BLink,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'Nr. zamówienia',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'total',
          label: 'Łącznie',
        },
        {
          // key: 'id',
          label: 'akcje',
          key: 'actions',
        },
      ],
      orders: [],
    }
  },
  created() {
    this.fetchOrders()
  },
  methods: {
    fetchOrders() {
      this.$http.get(`https://api.${this.$store.state.domain}/api/my/orders`).then(response => {
        console.log('ORDERS', response.data)
        this.orders = response.data.orders
        // this.$store.commit('app/UPDATE_CUSTOM_LAYOUT_MENU', response.data.sections)
      })
    },
  },
}
</script>

<style>

</style>
